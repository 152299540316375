import { IEnrichedUniswapPosition, IPositionToken } from '../types/uniswapTokens';
import { getAmountToBorrow } from './getAmountToBorrow';
import BigNumber from 'bignumber.js';
import { MIN_LEVERAGE } from '../components/transactions/LeveragePosition/constants';
import { getBorrowApyByAmount } from './getBorrowApyByAmount';

export const getBorrowApyByLeverage = (
  leverage: number,
  enrichedPosition?: IEnrichedUniswapPosition,
  tokenToBorrow?: IPositionToken,
) => {
  const amountToBorrow = getAmountToBorrow(enrichedPosition, tokenToBorrow, leverage);
  return getBorrowApyByAmount({ amountToBorrow, tokenToBorrow });
}


export const calculateEstAprForToken = (
  estimateYearlyFees: BigNumber,
  enrichedPosition?: IEnrichedUniswapPosition,
  tokenToBorrow?: IPositionToken
) => {
  const amountToBorrow = getAmountToBorrow(enrichedPosition, tokenToBorrow, tokenToBorrow?.availableLeverage);
  const totalBorrowed = BigNumber(enrichedPosition?.marketReferenceCurrencyValue || 0)
    .multipliedBy((tokenToBorrow?.availableLeverage || MIN_LEVERAGE) - 1);
  const borrowApy = getBorrowApyByAmount({ amountToBorrow, tokenToBorrow });
  return estimateYearlyFees
    .minus(BigNumber(totalBorrowed).multipliedBy(borrowApy).dividedBy(100))
    .div(
      BigNumber(enrichedPosition?.token0.netWorthUsd || 0).plus(enrichedPosition?.token1.netWorthUsd || 0)
    ).toNumber();
}
