import React from 'react';
import { ModalContextType, ModalType, useModalContext } from 'src/hooks/useModal';
import { ScrollModal } from '../../primitives/ScrollModal';
import { PositionModalWrapper } from '../FlowCommons/PositionModalWrapper';
import { AutoCompoundFeesModalContent } from './AutoCompoundFeesModalContent';

export const AutoCompoundFeesModal = () => {
  const { type, args, close } = useModalContext() as ModalContextType<{
    positionAddress: string;
  }>;

  return (
    <ScrollModal open={type === ModalType.AutoCompoundFees} setOpen={close}>
      <PositionModalWrapper
        positionAddress={args.positionAddress}
        modernStyle={true}
      >
        {(params) => <AutoCompoundFeesModalContent {...params} />}
      </PositionModalWrapper>
    </ScrollModal>
  );
};
