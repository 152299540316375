import { Box, BoxProps } from '@mui/material';
import React, { useEffect } from 'react';
import { TransactionResponse } from '@ethersproject/providers';
import { queryClient } from '../../../../pages/_app.page';
import { QueryKeys } from '../../../ui-config/queries';
import { useModalContext } from '../../../hooks/useModal';
import { useWeb3Context } from '../../../libs/hooks/useWeb3Context';
import { useRootStore } from '../../../store/root';
import { getErrorTextFromError, TxAction } from '../../../ui-config/errorMapping';
import { TxPositionActionsWrapper } from '../TxPositionActionsWrapper';

export interface DeleveragePositionActionsProps extends BoxProps {
  marketId?: number;
  positionAddress?: string;
  maxTotalFeePercent?: string;
  isWrongNetwork: boolean;
  initialized?: boolean;
  hasValueChanges?: boolean;
}

export const AutoCompoundFeesActions = React.memo(
  ({
     marketId,
     isWrongNetwork,
     positionAddress,
     maxTotalFeePercent,
     initialized,
     hasValueChanges,
  }: DeleveragePositionActionsProps) => {
    const { mainTxState, setMainTxState, setTxError, setGasLimit } = useModalContext();
    const { sendTx } = useWeb3Context();
    const chainId = useRootStore((store) => store.currentChainId);

    const [
      setupAutoCompound,
      cancelAutoCompound,
      estimateGasLimit,
    ] = useRootStore((state) => [
      state.setupAutoCompound,
      state.cancelAutoCompound,
      state.estimateGasLimit,
    ]);

    const setupAction = async () => {
      if (!marketId || !positionAddress || !maxTotalFeePercent || mainTxState.success) return;
      try {
        window.gtag('event', 'leverage_modal_setup_auto_compound');

        setTxError(undefined);
        setMainTxState({ ...mainTxState, loading: true, value: 'setup' });

        const setupAutoCompoundTxData = setupAutoCompound(marketId, positionAddress, maxTotalFeePercent);

        const response: TransactionResponse = await sendTx(setupAutoCompoundTxData);
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.YLDR_LEVERAGE_AUTOMATIONS, chainId, marketId, [positionAddress]],
        });
        await response.wait(1);

        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.YLDR_LEVERAGE_AUTOMATIONS, chainId, marketId, [positionAddress]],
        });

        setMainTxState({
          txHash: response.hash,
          loading: false,
          success: true,
        });

      } catch (error) {
        const parsedError = getErrorTextFromError(error, TxAction.GAS_ESTIMATION, false);
        setTxError(parsedError);
        setMainTxState({
          txHash: undefined,
          loading: false,
        });
      }
    };

    const cancelAction = async () => {
      if (!marketId || !positionAddress || mainTxState.success) return;
      try {
        window.gtag('event', 'leverage_modal_cancel_auto_compound');

        setTxError(undefined);
        setMainTxState({ ...mainTxState, loading: true, value: 'cancel' });

        const cancelAutoCompoundTxData = cancelAutoCompound(marketId, positionAddress);

        const response: TransactionResponse = await sendTx(cancelAutoCompoundTxData);
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.YLDR_LEVERAGE_AUTOMATIONS, chainId, marketId, [positionAddress]],
        });
        await response.wait(1);

        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.YLDR_LEVERAGE_AUTOMATIONS, chainId, marketId, [positionAddress]],
        });

        setMainTxState({
          txHash: response.hash,
          loading: false,
          success: true,
        });

      } catch (error) {
        const parsedError = getErrorTextFromError(error, TxAction.GAS_ESTIMATION, false);
        setTxError(parsedError);
        setMainTxState({
          txHash: undefined,
          loading: false,
        });
      }
    };

    //Update gas estimation
    useEffect(() => {
      if (!marketId || !positionAddress || !maxTotalFeePercent || mainTxState.success) return;
      const calculateGasLimit = async () => {
        const autoCompoundTx = initialized
          ? cancelAutoCompound(marketId, positionAddress)
          : setupAutoCompound(marketId, positionAddress, maxTotalFeePercent);
        const autoCompoundTxData = await estimateGasLimit(autoCompoundTx);
        setGasLimit(autoCompoundTxData.gasLimit?.toString() || '0');
      }

      const timerId = setTimeout(calculateGasLimit, 500);
      const intervalId = setInterval(calculateGasLimit, 15000);
      return () => {
        clearInterval(intervalId);
        clearTimeout(timerId);
      }
    }, [maxTotalFeePercent, mainTxState.success]);

    return (
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
        <TxPositionActionsWrapper
          mainTxState={mainTxState}
          disabledLoading={mainTxState.value === 'cancel'}
          isWrongNetwork={isWrongNetwork}
          requiresAmount
          preparingTransactions={false}
          actionText={initialized ? 'Apply changes' : 'Enable auto-compound'}
          actionInProgressText={initialized ? 'Applying changes' : 'Enabling auto-compound'}
          handleAction={setupAction}
          variantPositive={true}
          disabled={!hasValueChanges && initialized}
        />
        {initialized && (
          <TxPositionActionsWrapper
            mainTxState={mainTxState}
            disabledLoading={mainTxState.value === 'setup'}
            isWrongNetwork={isWrongNetwork}
            requiresAmount
            preparingTransactions={false}
            actionText="Disable auto-compound"
            actionInProgressText="Disabling auto-compound"
            handleAction={cancelAction}
            variantPositive={false}
            sx={{ mt: 0 }}
          />
        )}
      </Box>
    );
  }
);
