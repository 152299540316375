import React, { useLayoutEffect, useRef, useState } from 'react';
import BigNumber from 'bignumber.js';
import { Box, InputBase, Typography } from '@mui/material';
import { Trans } from '@lingui/macro';

import { calculateAutoCompoundValues } from '../../../utils/calculateAutoCompoundValues';
import { displayRangeValue } from '../../../utils/displayRangeValue';
import { useModalContext } from '../../../hooks/useModal';
import { useRootStore } from '../../../store/root';
import { validateValue } from '../../../utils/validateValue';
import { useConfiguredAutomationsQuery } from '../../../hooks/yldr/useConfiguredAutomationsQuery';
import { ScrollModalContent, ScrollModalFooter, ScrollModalHeader } from '../../primitives/ScrollModal';
import { useEstAprValues } from '../../UniswapPositionsList/hooks/useEstAprValues';
import { TextWithTooltip } from '../../TextWithTooltip';
import { TxModalTitleModern } from '../FlowCommons/TxModalTitle';
import { PositionModalWrapperProps } from '../FlowCommons/PositionModalWrapper';
import { DetailsNumberLine, TxModalDetails } from '../FlowCommons/TxModalDetails';
import { TxSuccessView } from '../FlowCommons/Success';
import { SLIPPAGE_WARNING } from '../constants';
import { AutoCompoundFeesActions } from './AutoCompoundFeesActions';
import {
  FEE_PERCENT_TO_COMPOUND_DEFAULT,
  FEE_PERCENT_TO_COMPOUND_MAX,
  FEE_PERCENT_TO_COMPOUND_MIN,
} from './constants';
import { AlertBanner } from '../../../ui-kit/AlertBanner';

export const AutoCompoundFeesModalContent = React.memo(
  ({ enrichedPosition, positionAddress, isWrongNetwork }: PositionModalWrapperProps) => {
    const [inputValue, setInputValue] = useState<string>(FEE_PERCENT_TO_COMPOUND_DEFAULT.toString());
    const [autoCompoundInitialized, setAutoCompoundInitialized] = useState(false);
    const { mainTxState, gasLimit, txError, setTxError } = useModalContext();
    const currentNetworkConfig = useRootStore((store) => store.currentNetworkConfig);
    const inputRef = useRef<HTMLInputElement>(null);

    const { estApr, revenueFee } = useEstAprValues({ position: enrichedPosition });
    const { data: configuredAutomationsData } = useConfiguredAutomationsQuery({
      marketId: enrichedPosition.marketId,
      positions: positionAddress ? [positionAddress] : undefined,
    });

    const initialized = configuredAutomationsData?.compounds[0].initialized;
    const autoCompoundSaved = configuredAutomationsData?.compounds[0].maxTotalFeePercent.div(100).toString();
    const hasValueChanges = initialized && inputValue !== autoCompoundSaved;

    const autoCompoundFeesActionsProps = {
      marketId: enrichedPosition.marketId,
      maxTotalFeePercent: BigNumber(inputValue || 0).multipliedBy(100).toFixed(0),
      initialized: autoCompoundInitialized,
      hasValueChanges,
      positionAddress,
      isWrongNetwork,
    };

    const autoCompoundValues = calculateAutoCompoundValues({
      positionApr: estApr - revenueFee,
      borrowed: enrichedPosition.marketReferenceCurrencyDebt,
      positionValue: enrichedPosition.marketReferenceCurrencyValue,
      compoundGasCost: currentNetworkConfig.compoundGasCost,
      compoundCostCap: Number(inputValue),
    });

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value
        .replace(/[^\d.,]/g, '')
        .replace(',', '.');
      const newValue = (Number(value) > FEE_PERCENT_TO_COMPOUND_MAX ? FEE_PERCENT_TO_COMPOUND_MAX : value).toString();
      const isValidValue = validateValue(newValue, 1);
      if (isValidValue) {
        setInputValue(newValue.toString());
      }
      setTimeout(() => {
        if (inputRef.current) {
          const cursorPosition = isValidValue ? newValue.length : inputValue.length;
          inputRef.current.selectionStart = cursorPosition;
          inputRef.current.selectionEnd = cursorPosition;
        }
      }, 0);
    };

    const handleOnBlur = () => {
      const newValue = Number(inputValue) < FEE_PERCENT_TO_COMPOUND_MIN ? FEE_PERCENT_TO_COMPOUND_MIN : inputValue;
      setInputValue(newValue.toString());
    };

    const handleOnFocus = () => {
      setTimeout(() => {
        if (inputRef.current) {
          const cursorPosition = inputValue.toString().length;
          inputRef.current.selectionStart = cursorPosition;
          inputRef.current.selectionEnd = cursorPosition;
        }
      }, 0);
    };

    useLayoutEffect(() => {
      if (initialized && autoCompoundSaved) {
        setInputValue(autoCompoundSaved);
        setAutoCompoundInitialized(initialized)
      }
    }, [initialized, autoCompoundSaved]);

    if (mainTxState.success) {
      return (
        <ScrollModalContent>
          <TxSuccessView
            action={<Trans>successfully enabled</Trans>}
            prefix="$"
            amount={mainTxState.value}
          />
        </ScrollModalContent>
      );
    }

    return (
      <>
        <ScrollModalHeader withDivider={true}>
          <TxModalTitleModern
            title={autoCompoundInitialized ? 'Disable auto-compound' : 'Enable auto-compound'}
            marketName={enrichedPosition?.marketName}
            tokenA={enrichedPosition?.token0}
            tokenB={enrichedPosition?.token1}
            fee={enrichedPosition?.fee}
          />
        </ScrollModalHeader>
        <ScrollModalContent>
          <Box sx={{
            my: 5,
            padding: '12px 16px 16px',
            borderRadius: '10px',
            border: '1px solid rgba(255, 255, 255, 0.06)',
            background: 'radial-gradient(302.62% 77.77% at 0% 0%, rgba(128, 97, 229, 0.14) 0%, rgba(128, 97, 229, 0.00) 100%), radial-gradient(253.43% 70.61% at 100% 100%, rgba(128, 97, 229, 0.12) 0%, rgba(128, 97, 229, 0.00) 100%), #1F1F23'
          }}>
            <Typography variant="main15" sx={{ mb: 1, color: '#8061E5' }}>
              Boost your APR up to {displayRangeValue(autoCompoundValues.apr)}%
            </Typography>
            <Typography variant="secondary13" color="text.secondary">
              YLDR automatically reinvests uncollected fees back into the position whenever it can cover the transaction
              cost by taking {inputValue}% of the accumulated fees.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="secondary13" color="text.tertiary">
                Maximum transaction cost
              </Typography>
              <TextWithTooltip>
                <>
                  This represents the maximum percentage of accumulated fees that will be used to cover the transaction
                  cost for each compound.
                </>
              </TextWithTooltip>
            </Box>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              px: 3,
              py: 2,
              borderRadius: '8px',
              border: '1px solid rgba(255, 255, 255, 0.06)',
              backgroundColor: '#2A2A32',
              'input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
              },
              'input[type="number"], input[type="number"]:hover, input[type="number"]:focus': {
                appearance: 'none',
                '-moz-appearance': 'textfield',
              }
            }}>
              <InputBase
                inputRef={inputRef}
                value={`${inputValue}%`}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onFocus={handleOnFocus}
                sx={{ flex: 1 }}
              />
            </Box>
          </Box>

          <TxModalDetails
            description={SLIPPAGE_WARNING}
            gasLimit={gasLimit}
            skipLoad={true}
          >
            <DetailsNumberLine
              captionColor="text.secondary"
              description="Est. yearly compounds"
              value={autoCompoundValues.compounds}
              visibleDecimals={0}
            />
            <DetailsNumberLine
              captionColor="text.secondary"
              description="Protocol fee"
              tooltip="This fee covers transaction costs and flash loans. It will be applied to each compound and added to the total debt amount."
              value={autoCompoundValues.compoundCost}
              numberPrefix="$"
            />
          </TxModalDetails>

          {txError && (
            <AlertBanner variant="error" txError={txError} onClose={() => setTxError(undefined)} />
          )}
        </ScrollModalContent>

        <ScrollModalFooter>
          <AutoCompoundFeesActions {...autoCompoundFeesActionsProps} />
        </ScrollModalFooter>
      </>
    );
  }
);
